.count {
	display: inline-flex;
	padding: 8px 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	background: var(--Fill-White, #FFF);
	cursor: pointer;
	position: relative;
	border-radius: 100px;
	background-clip: padding-box; /* Ensure the background color is inside the border */
}


.count::before {
	content: '';
	position: absolute;
	top: -1px;
	right: -1px;
	bottom: -1px;
	left: -1px;
	z-index: -1;
	border-radius: inherit; /* Ensure the pseudo-element has the same border radius */
	background: linear-gradient(98.35deg, #8C56FF 22.71%, #C956FF 73.26%);
}

.countValue {
	background: var(--Gradient-Accent, linear-gradient(98deg, #8C56FF 22.71%, #C956FF 73.26%));
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.mobileCount {
	display: inline-flex;
	padding: 8px 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 100px;
	cursor: pointer;
	border: 1px solid transparent;
	background: linear-gradient(rgba(245, 240, 255, 1), rgba(245, 240, 255, 1) 0) padding-box,
	linear-gradient(98deg, #8c56ff 22.71%, #c956ff 73.26%) border-box;
}