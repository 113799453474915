.advice_panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 8px;
  /* Fill/Button */
  background: #F5F0FF;
  border-radius: 20px;
  /*width: 95%;*/

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  
}

.advice_panel__header {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  /* Accent/Violet/Dark */
  color: #7948E1;
  flex: none;
  order: 0;
  flex-grow: 0;

}


.advice_panel__text {
  /* Paragraph/M */
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;

  /* Text/Black/Primary */
  color: #161B33;
}

.advice_panel__body {
  width: 100%;
  display: flex;
}