.Container {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.ListContainer {
  margin-bottom: 24px;
}

.Actions {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.CurrentTariffLabel {
  font-size: 12px;
  margin-bottom: 8px;
  color: hsla(230, 40%, 14%, 0.6);
}

.Desktop {
  display: block;
}

.Mobile {
  display: none;
}

@media screen and (max-width: 800px) {
  .Container {
      width: 247px;
  }
}

@media (max-width: 481px) {
  .Desktop {
    display: none;
  }
  .Mobile {
    display: block;
  }
  .Container {
    width: 100%;
  }
}
