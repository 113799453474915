.Card {
  border-radius: 20px;
  border: 2px solid hsla(230, 40%, 14%, 0.1);
  padding: 20px;
  min-width: 556px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Content {
  display: flex;
  gap: 10px;
  align-items: center;
}

.Text {
  color: hsla(230, 40%, 14%, 1);
}

@media (max-width: 481px) {
  .Card {
    flex-direction: column;
    max-width: 100%;
    min-width: inherit;
    gap: 12px;
  }
}
