.tab-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
}

.tab-container {
  display: flex;
  position: relative;
  background-color: #FFFFFF66;
  border-radius: 12px;
  width: 100%;
  max-width: 600px;
  height: 48px;
  border: 1px solid #ffffff;
  overflow: hidden;
}

.tab {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: opacity 0.3s ease;
  text-align: center;
}

.inactive {
  opacity: 60%;
}

.active {
  opacity: 100%
}

.background-slider {
  position: absolute;
  top: 3px;
  bottom: 3px;
  left: 3px;
  width: calc(50% - 6px);
  background-color: white;
  border-radius: 10px;
  border: 0.25px solid #161B331A;
  transition: transform 0.3s ease;
  transform: translateX(calc(var(--tab-index) * 100% + (var(--tab-index) * 6px)));
  z-index: 1;
}
