.tool-panel_content_template {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    color: var(--Text-Black-Tertiary);
    width: 94px;
}

.tool-panel_content_template:hover *, .tool-panel_content_template:active *, .tool-panel_content_template.active * {
    color: var(--Accent-Violet-Dark,#7948e1) !important;
    border-color: var(--Accent-Violet-Dark,#9F72FF) !important;

}

.tool-panel_content_template_delete:hover *, .tool-panel_content_template_delete.active * {
    fill: #9f72ff;
}

.tool-panel_content_template_image {
    width: 94px;
    height: 94px;
    border-radius: 8px;
    border: 2px solid var(--Stroke-Default);
}