.Card {
  border: 2px solid hsla(230, 40%, 14%, 0.1);
  border-radius: 20px;
  padding: 20px;
  min-width: 450px;
}

.nextPayDateLabel {
  color: hsla(230, 40%, 14%, 0.6);
}
.CardContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.PlanName {
  border: 2px solid hsla(259, 100%, 67%, 1);
  padding: 6px 16px;
  color: hsla(259, 100%, 67%, 1);
  border-radius: 50px;
}

.PlanRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SubscriptionDetails {
  border-radius: 12px;
  background-color: hsla(240, 20%, 95%, 0.5);
  padding: 12px;
}

.DetailsCollapsibleHeader {
  display: flex;
  padding: 0 4px;
  margin-bottom: 4px;
  gap: 4px;
  cursor: pointer;
  align-items: center;
  color: hsla(230, 40%, 14%, 1);
}
.FeaturesList {
  margin-top: -4px;
  font-weight: 400;
  font-family: PT Sans;
  font-size: 16px;
  color: hsla(230, 40%, 14%, 0.6);
}

.FeatureIcon svg {
  display: inline;
}
.Feature {
  display: flex;
  gap: 8px;
  /* align-items: center; */
}

@media (max-width: 481px) {
  .Card {
    min-width: 100%;
  }
  .FeaturesList{
    font-size: 13px;
  }
}

@media (max-width: 380px) {
  .Card {
    min-width: 344px;
  }
}

@media (max-width: 820px) {
  .Card {
    min-width: 100%;
  }
}
