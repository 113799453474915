.borderWithTitle {
  position: relative;
  text-align: center;
  color: rgba(22, 27, 51, 0.4);
}

.borderWithTitle::before,
.borderWithTitle::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 50%;
  border-top: 1px solid rgba(22, 27, 51, 0.4);
}

.borderWithTitle::before {
  left: 0;
}

.borderWithTitle::after {
  right: 0;
}

.borderWithTitle span {
  background-color: #fff;
  padding: 0 10px;
  position: relative;
  z-index: 1;
}
