.Wrapper {
  margin-top: 24px;
  margin-bottom: 38px;
}

.InputBlock {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 24px;
}


@media (max-width: 481px) {
  .InputBlock {
    
    flex-direction: column;
  }
  .input {
    width: 100%;
  }
}

.ButtonBlock {
  margin-top: 24px;
}


.input {
    display: flex;
    padding: 17px 24px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 50px;
    border: 1px solid var(--Stroke-Default, rgba(22, 27, 51, 0.2));
    backdrop-filter: blur(15px);
}


/* .error {
  border: 1px solid hsla(324, 69%, 61%, 1);
  
} */



.errorText {
    color: var(--Accent-Pink-Dark, #e055a9);
    font-size: 12px;
    text-align: left;
    display: block;
    width: 100%;
    padding-left: 24px;
    margin-top: 4px;
}

.ActionButton {
  font-weight: 700;
  font-size: 16px;
  text-decoration: underline;
  line-height: 21.82px;
  cursor: pointer;
  color: hsla(259, 100%, 72%, 1);
  display: inline;
}

.FlexBlock {
  display: flex;
  justify-content: center;
}

.AppliedBlock {
  padding: 12px 24px 12px 16px;
  background-color: hsla(156, 100%, 95%, 1);
  border-radius: 8px;
  display: flex;
  gap: 6px;
  align-items: center;

  justify-content: center;
  max-width: 322px;
  color: rgba(14, 179, 110, 1);
}

.AppliedCodeText {
  font-weight: 700;
  font-size: 16px;
  text-decoration: underline;
  line-height: 21.82px;
}

.checkIcon svg {
  fill: rgba(14, 179, 110, 1);
  margin-top: 4px;
}
