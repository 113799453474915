.NoProjectsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 48px;

  width: 80%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1156px;
}

.Content {
  text-align: center;
}

.image img {
  width: 228px;
  height: 228px;
  margin-bottom: 2px;
}

.Header {
  font-weight: 700;
  font-size: 22px;
  line-height: 30.01px;
  margin-bottom: 2px;
  color: hsla(230, 40%, 14%, 1);
}


.SubHeader {
  color: hsla(230, 40%, 14%, 0.6);
  font-weight: 400;
  font-family: 'PT Sans';
}
