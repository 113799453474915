.Page {
  background-color: #fff;
  padding: 32px 24px;
  overflow-y: auto;
  max-height: 100vh;
}

.BackButton {
  display: flex;
  color: hsla(230, 40%, 14%, 1);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  align-items: center;
  gap: 4px;
}

.BackButtonContainer {
  border: 2px solid var(--Stroke-Light);
}

.BackButton svg {
  margin-top: 3px;
}

.HeaderContainer {
  text-align: center;
  margin-bottom: 48px;
}
.Header {
  font-size: 48px;
  line-height: 56px;
  font-weight: 800;
  margin-bottom: 12px;
}

.SubHeader {
  font-size: 24px;
  line-height: 31.08px;
  font-weight: 400;
}

.hg {
  color: hsla(259, 100%, 72%, 1);
  font-weight: 700;
}

.TabsContainer {
  margin-bottom: 88px;
}

.PlanContainer {
  width: 1082px;
  margin: auto;
  display: flex;
  gap: 24px;
  margin-bottom: 80px;
}

.TopActions {
  display: flex;
  align-items: center;
  justify-content: start;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltip_text {
  visibility: hidden;
  width: 316px;
  background-color: rgba(121, 72, 225, 1);
  color: rgba(255, 255, 255, 1);
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -158px;
  opacity: 0;
  transition: opacity 0.3s;

  font-family: PT Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.tooltip .tooltip_text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(121, 72, 225, 1) transparent transparent transparent; /* Make the arrow */
}

.tooltip:hover .tooltip_text,
.tooltip:focus .tooltip_text {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 481px) {
  .Page {
    background-color: #fff;
    height: 100vh;
    height: 100dvh;
    padding: 32px 16px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .Header {
    font-size: 32px;
    line-height: 36px;
  }

  .SubHeader {
    font-size: 16px;
  }

  .PlanContainer {
    flex-direction: column;
    margin-bottom: 24spx;
    width: auto;
  }
  .TopActions {
    /* set center to center the button */
    justify-content: start;
  }
}

@media (max-width: 658px) {
  .PlanContainer {
    overflow-x: scroll;
  }
}
