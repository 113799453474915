.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-content: center;
}

.socialBtn {
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(22, 27, 51, 0.1);
  color: #161b33;
  /* rgba(22, 27, 51, 1) */
}

.socialBtn:hover {
  background: rgba(159, 114, 255, 0.04);
  border: 2px solid rgba(212, 200, 239, 1);
  color: rgba(121, 72, 225, 1);
}

.socialBtn:active {
  background: rgba(159, 114, 255, 0.08);
  border: 2px solid rgba(200, 182, 243, 1);
  color: #161b33;
  color: rgba(121, 72, 225, 1);

}

.buttonInner {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: center;
  align-content: center;
  align-items: center;
}
