.aiCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.aiCardImage {
  width: 147px;
  height: 196px;
  border-radius: 8px;
  border: 3px solid var(--Stroke-White, #fff);
}

.aiCardImageSquare {
  width: 147px;
  border-radius: 8px;
  border: 3px solid var(--Stroke-White, #fff);
}

.aiCardImage:hover,
.aiCardImage:active,
.tool-panel_content_template.active * {
  border-color: var(--Accent-Violet-Dark, #7948e1);
}

@media (max-width: 800px) {
  .aiCardImage {
    height: 152px;
    width: 114px;
  }
}
