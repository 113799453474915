.layout {
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
}

.layoutWithOutScroll{
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #fff;
}

.ContentWrapper {
  padding-top: 24px;
  width: 100%;
  max-height: 100%;
  min-height: calc(100vh - 63px);
}
