.removeBackgroundToggle {
    width: 100%;
    text-align: left;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.formControlLabel {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.labelContainer {
    display: flex;
    align-items: center;
}

.labelWrapper {
    display: flex;
    align-items: center;
}


.toggleLabel {
    margin-right: 18px;
    justify-self: stretch;
    line-height: 24px;
}

.panel_heading {
    padding: 24px 0 20px 24px;
}

.beta {
    margin-bottom: 24px;
    background: #F5F0FF;
    padding: 12px;
    border-radius: 8px;
    width: 310px;
    font-family: Nunito, sans-serif;
}

.betaText {
    font-size: 12px;
}

.removeBackgroundIcon {
    fill: var(--Accent-Violet-Default);
    height: 18px;
    width: 18px;
    vertical-align: middle;
    margin: 3px;
    position: relative;
    bottom: 2px;
}

.upload_container {
    margin-top: 4px;
    border-radius: 20px;
    border: 2px dashed var(--Stroke-Default);
    height: 192px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    outline: none;
}

.upload_container:active{
    outline: none;
}

.thumbs_container {
    border-radius: 20px;
    border: 2px dashed var(--Stroke-Default);
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    align-items: start;
    justify-content: left;
    padding: 20px;
}

.content_wrapper {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    overflow-y: auto;
    height: 100%;
}

.upload_link:hover, .upload_link:active {
    outline: none;
    color: var(--Accent-Violet-Default);
}

.upload_icon {
    width: 32px;
    height: 32px;
    fill: var(--Accent-Violet-Default);
}

.add_images_button {
    display: flex;
    font-style: normal;
    line-height: normal;
    justify-content: center;
    align-items: center;
    gap: 8px;

    cursor: pointer;
    border: none;
    outline: none;
}

.add_images_button svg {
    width: 19px;
    height: 19px;
    top: 7px;
    left: 7px;
    gap: 0;
    fill: var(--Accent-Violet-Default);
}

.upload_preview {
    position: relative;
    display: inline-flex;
    width: 82px;
    height: 82px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid var(--Stroke-Default);
}

.preview_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.upload_delete {
    cursor: pointer;
    position: absolute;
    width: 24px;
    height: 24px;
    background: var(--Fill-White);
    border-radius: 4px;
    right: 4px;
    top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload_delete svg {
    fill: var(--Text-Black-Tertiary);
    width: 16px;
    height: 16px;
}

.upload_inner {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.upload_button {
    cursor: pointer;
    position: relative;
    display: inline-flex;
    width: 82px;
    height: 82px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px dashed var(--Accent-Violet-Default);
    background: var(--Fill-Button);
    justify-content: center;
    align-items: center;
}

.upload_button svg {
    height: 32px;
    width: 32px;
    fill: var(--Accent-Violet-Default);
}

.aboutText {
    padding: 0px 0px 18px;
    font-family:
        PT Sans,
        serif;
    font-size: 16px;
    color: rgba(22, 27, 51, 0.6);
    font-weight: 400;
}

@media (max-width: 800px) {
    .upload_container{
        height: 100%;
        min-height: 75px;
    }
    .beta {
        margin-left: auto;
        margin-right: auto;
    }
    .aboutText {
        text-align: center;
        width: 100%;
    }
}
