.Wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.Item {
  padding: 17px 24px 17px 24px;
  cursor: pointer;
  text-align: center;
  color: hsla(230, 40%, 14%, 0.4);
}

.Item.Active {
  color: hsla(259, 100%, 72%, 1);
  background: hsla(260, 100%, 97%, 1);
  border-radius: 50px;
}

@media (max-width: 481px) {
  .Wrapper {
    overflow-x: scroll;
  }
}
