.PageLayout {
  padding-bottom: 20px;
}


.ProjectHeader {
  font-weight: 800;
  margin-bottom: 24px;
}

.ProjectListContainer {
  width: 80%;
  padding: 0px;
  margin: 48px auto;
  max-width: 1156px;
}

.LoadingContainer {
  margin: 100px auto 50px auto;
  display: flex;
  justify-content: center;
  width: 1156px;
}


.LoadingContainer svg {
  width: 96px;
  height: 96px;
}

@media (max-width: 650px) {
  .ProjectHeader{
    font-weight: 700;
  }
  
  .ProjectListContainer {
    width: 91%;
  }
  .LoadingContainer {
  }
}


