.ContentWrapper {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(240, 240, 245, 1);
}

.NoHeader {
  min-height: 100vh;
  min-height: 100dvh;
}

.WithHeader {
  min-height: calc(100vh - 64px);
  min-height: calc(100dvh - 64px);
}

@media (max-width: 450px) { 
  .ContentWrapper {
    align-items: flex-start; 
    min-height: auto;
    max-height: none; 
  }
}

@media (max-height: 830px) {
  .WithHeader {
    height: auto;
  }
}