@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.Loading_loader {
    animation: spin 2s linear infinite;
}
