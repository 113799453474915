.dialog {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  /* 64px - header height */
  /* top: 64px; */
  left: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  z-index: 102;
}

.inner_wrapper {
  /* border-radius: 60px; */
  background-color: #fff;
  /*max-height: 486px;*/
  height: 100vh;
  width: 100vw;
}

/* .inner_wrapper_wide {
  min-width: 300px;
  min-height: 646px;
} */

.top_dock {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin: 40px 40px 0 40px;
}

.cursor_pointer {
  cursor: pointer;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
}

.container {
  padding: 0;
}

.body {
  display: block;
}

/*  Mobile styles. Must be deleted in the future */
/* fit to editor mobile view */
/* @media (max-width: 690px) {
  .inner_wrapper {
    margin: 0 16px;
  }
  h1 {
    font-size: 22px;
  }
} */

@media (max-width: 481px) {
  .container {
    padding: 0 8px;
  }
}
