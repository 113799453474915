.Container {
  max-width: 1080px;
  margin-top: 88px;
}

.ContentWrapper {
  min-height: 100vh;
  min-height: 100dvh;
}

.BackButton {
  display: flex;
  color: hsla(230, 40%, 14%, 1);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  align-items: center;
  gap: 4px;
}

.BackButtonContainer {
  border: 2px solid var(--Stroke-Light);
}

.BackButton svg {
  margin-top: 3px;
}

.Header {
  margin-top: 18px;
  margin-bottom: 48px;
  line-height: 32px;
}

.Content {
  display: flex;
  flex-direction: row;
  gap: 48px;
}

@media (max-width: 481px) {
  .ContentWrapper {
    padding-bottom: 120px;
    margin-top: 24px;
    width: 100%;
  }
  .Content {
    flex-direction: column;
  }

  .Header {
    text-align: center;
    margin-top: 36px;
    margin-bottom: 12px;
  }
}
