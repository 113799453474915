.header {
  display: flex;
  width: 100%;
  height: 64px;
  padding: 0 24px;
  align-items: center;
  border-bottom: 1px solid var(--Stroke-Light, rgba(22, 27, 51, 0.1));
  background: var(--Fill-White, #fff);
  z-index: 50;
  position: relative;
}

.btnDwlMobileHeader{
  border-radius: 50%;
  padding: 0 12px;
  width: 40px;
  min-width: 40px;
}

.btnBlackFridayMobileHeader{
  width: 44px;
  height: 44px;
  padding: 0px;
  min-width: 44px
}

.btnBlackFridayDesktopHeader{
  display: flex;
  width: 191px;
  height: 48px;
  gap: 5px;
  align-items: center;
  padding: 12px 17px;
  margin-right: 12px;

  background: radial-gradient(49.86% 49.86% at 49.86% 92.68%, rgba(129, 47, 251, 1) 0%, rgba(108, 0, 231, 1) 75.47%, rgba(85, 10, 192, 1) 100%);
  border: 2px solid rgb(160,141,255);
  color: #FDF9FF;
}
.btnBlackFridayDesktopHeader:hover {
  color: #fff;
  background: radial-gradient(49.86% 49.86% at 49.86% 92.68%, rgba(129, 47, 251, 0.9) 0%, rgba(108, 0, 231, 0.9) 75.47%, rgba(85, 10, 192, 0.9) 100%);
}

.btnBlackFridayDesktopHeader:active {
  color: #fff;
  background: radial-gradient(49.86% 49.86% at 49.86% 92.68%, rgba(129, 47, 251, 0.8) 0%, rgba(108, 0, 231, 0.8) 75.47%, rgba(85, 10, 192, 0.8) 100%);
}

.btnBlackFridayDesktopHeader.disabled {
  color: #fff;
  opacity: 0.4;
  background: radial-gradient(49.86% 49.86% at 49.86% 92.68%, rgba(129, 47, 251, 0.8) 0%, rgba(108, 0, 231, 0.8) 75.47%, rgba(85, 10, 192, 0.8) 100%);
}

.header_desktop {
  width: 100%;
  justify-content: space-between;
  flex-shrink: 0;
  align-items: center;
}

.header_mobile {
  width: 100%;
  justify-content: space-between;
  flex-shrink: 0;
  align-items: center;
}

.header_logo {
  width: 90px;
  height: 56px;
  flex-shrink: 0;
}

.header_items {
  display: flex;
  height: 64px;
  padding: 4px 8px 4px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  border-radius: 50px;
  backdrop-filter: blur(0px);
}

.divider {
  width: 2px;
  height: 48px;
  background: var(--Stroke-Light, rgba(22, 27, 51, 0.1));
  margin-left: 24px;
  margin-right: 24px;
}

.burgerButton {
  cursor: pointer;
}

.header_nav_logoAndCreditsBackgroynd {
  display: flex;
  gap: 16px;
  width: 90%;
  background-color: rgba(245, 240, 255, 1);
  border-radius: 20px;
  padding: 12px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 9px;
  margin-bottom: 20px;
}

.avatar {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid var(--Accent-Violet-Default, #9f72ff);
  background: var(--Fill-Background, #f0f0f5);
  cursor: pointer;
}

.menu_list {
  margin: 0;
  padding: 0;
  width: 100%;
  list-style-type: none;
}

.menu_item {
  display: flex;
  color: rgba(22, 27, 51, 1);
  cursor: pointer;
}

.menu_item_out {
  color: rgba(224, 85, 169, 1);
}

.menu_item:nth-child(n + 2) {
  margin-top: 15px;
}

.mobileMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mobileMenu_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mobileMenu_item {
  display: flex;
  color: rgba(22, 27, 51, 1);
  cursor: pointer;
}

.mobileMenu_item_out {
  color: rgba(224, 85, 169, 1);
}

.mobileMenu_item:nth-child(n + 2) {
  margin-top: 15px;
}

.mobileMenu_line {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.mobileAvatar {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid var(--Accent-Violet-Default, #9f72ff);
  background: var(--Fill-Background, #f0f0f5);
}

.telegram_button_block_inHeader {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.telegram_button_inHeader {
  background: #9f72ff;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  color: #fff;
  text-align: center;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(44, 0, 139, 0.2);
}

.telegram_button_inHeader a {
  color: #fff;
}

.telegram_button_inHeader:hover {
  background: #7948e1;
}

@media screen and (max-width: 800px) {
  .header {
    padding: 0 16px 0 24px;
  }

  .header_items {
    padding: 4px 0px 4px;
  }
}

.minW {
  min-width: 212px;
}


.BackButton {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 64px;
  cursor: pointer;
}

.BackButton svg {
  fill: hsla(259, 72%, 58%, 1);
  height: 32px;
  width: 32px;
}
.BackButton .Text {
  font-size: 16px;
  font-weight: 700;
  line-height: 21.82px;
  color: hsla(230, 40%, 14%, 1);
}
