/* @deprecated since Jun 21 2024 by iSavi */
.button {
  display: flex;
  height: 56px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  background: var(--Gradient-Accent, linear-gradient(98deg, #8c56ff 22.71%, #c956ff 73.26%));
  cursor: pointer;
}

.buttonPrimary {
  display: flex;
  height: 56px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  background: var(--Gradient-Accent, linear-gradient(98deg, #8c56ff 22.71%, #c956ff 73.26%));
  cursor: pointer;
}

.buttonSecondary {
  display: inline-flex;
  height: 56px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 2px solid var(--Accent-Pink-Dark, #e055a9);
  width: 100%;
  cursor: pointer;
}

.buttonOther {
  display: flex;
  padding: 14px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 50px;
  background: var(--Fill-Button, rgb(245, 240, 255));
  cursor: pointer;
}

.buttonLeft {
  display: inline-flex;
  height: 56px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 50px;
  cursor: pointer;
  background: rgba(245, 240, 255, 1);
}

.buttonDownload {
  display: flex;
  height: 40px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  background: var(--Gradient-Accent, linear-gradient(98deg, #8c56ff 22.71%, #c956ff 73.26%));
  cursor: pointer;
}

.buttonRight {
  display: flex;
  height: 56px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  background: var(--Gradient-Accent, linear-gradient(98deg, #8c56ff 22.71%, #c956ff 73.26%));
  cursor: pointer;
}

.buttonCenter {
  display: flex;
  height: 56px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  background: var(--Gradient-Accent, linear-gradient(98deg, #8c56ff 22.71%, #c956ff 73.26%));
  cursor: pointer;
}

a.buttonLeft:active,
a.buttonLeft:hover,
a.buttonLeft {
  text-decoration: none;
  color: var(--Accent-Violet-Default, #9f72ff);
}

a.buttonRight:active,
a.buttonRight:hover,
a.buttonRight {
  text-decoration: none;
  color: var(--Text-White-Primary, #fff);
}

a.buttonCenter:active,
a.buttonCenter:hover,
a.buttonCenter {
  text-decoration: none;
  color: var(--Text-White-Primary, #fff);
}
