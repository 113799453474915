/* General styles */
.button {
  cursor: pointer;
  outline: 0;
  display: inline-block;
  line-height: 21.82px;
  min-width: 106px;
  display: inline-block;
  min-width: 80px;
  white-space: nowrap;
  text-align: center;
  border: 1px solid transparent;
  user-select: none;
  border-radius: 50px;
  -webkit-appearance: button;
  text-transform: none;
  /* overflow: visible; */
  /* block of text wrapping */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* end block of text wrqppint */
  text-decoration: none;
  margin: 0;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    background 0.15s ease-in-out,
    /* border-color 0.15s ease-in-out, */ box-shadow 0.15s ease-in-out;
}
.button:focus {
  outline: 0;
}

.fw {
  width: 100%;
}

.iconButton {
  cursor: pointer;
  outline: 0;
  display: inline-block;
  line-height: 21.82px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  white-space: nowrap;
  text-align: center;
  background: none;
  border: none;
  user-select: none;
  -webkit-appearance: button;
  overflow: visible;
  margin: 0;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    background 0.15s ease-in-out,
    /* border-color 0.15s ease-in-out, */ box-shadow 0.15s ease-in-out;
}

.iconButton svg {
  fill: #fff;
}

a.button,
a.button:hover,
a.button:visited {
  line-height: 29px;
  text-decoration: none;
}

/* end general styles */

/* size variation */

.size-m {
  padding: 12px 24px;
  height: 56px;
  font-size: 16px;
  min-width: 100px;
}
.size-m .svgContent > svg {
  width: 64px;
  height: 64px;
}

.size-s {
  padding: 8px 16px;
  height: 40px;
  font-size: 12px;
  min-width: 62px;
}

.size-s .svgContent > svg {
  width: 48px;
  height: 48px;
}

.size-xs {
  height: 32px;
  padding: 4px 12px;
  font-size: 12px;
  min-width: 50px;
}

.size-xs .svgContent > svg {
  width: 36px;
  height: 36px;
}
/* end size variation */

/* variants */

/* ### Primary */
.primary {
  color: #fff;
  /* background: linear-gradient(98deg, #8c56ff 22.71%, #c956ff 73.26%); */
  background: var(--Gradient-Accent, linear-gradient(98deg, #8c56ff 22.71%, #c956ff 73.26%));
}
.primary:hover {
  color: #fff;
  /* background: linear-gradient(90deg, #9f72ff 22.71%, #9f72ff 73.26%); */
  background: linear-gradient(0deg, rgba(22, 27, 51, 0.2) 0%, rgba(22, 27, 51, 0.2) 100%),
    linear-gradient(98deg, #8c56ff 22.71%, #c956ff 73.26%);
}

.primary:active {
  color: #fff;
  /* background: linear-gradient(90deg, #9f72ff 22.71%, #9f72ff 73.26%); */
  background: linear-gradient(0deg, rgba(22, 27, 51, 0.3) 0%, rgba(22, 27, 51, 0.3) 100%),
    linear-gradient(98deg, #8c56ff 22.71%, #c956ff 73.26%);
}

.primary.disabled {
  color: #fff;
  opacity: 0.4;
  background: var(--Gradient-Accent, linear-gradient(98deg, #8c56ff 22.71%, #c956ff 73.26%));
}

/* ### Secondary */
.secondary {
  color: #fff;
  background: transparent;
  border: 2px solid #fff;
}
.secondary:hover {
  color: #ece3ff;
  border: 2px solid #ece3ff;
  background: rgba(159, 114, 255, 0.08);
}

.secondary:active {
  color: rgba(159, 114, 255, 0.12);
  border: 2px solid #e2d5ff;
  background: rgba(159, 114, 255, 0.12);
}

.secondary.disabled {
  opacity: 0.4;
  color: #fff;
  background: #f5f0ff;
  border: 2px solid #fff;
}

/* ### Tertiary  */
.tertiary {
  color: #9f72ff;
  background: #f5f0ff;
}
.tertiary:hover {
  color: #7948e1;
  /* background: linear-gradient(0deg, #f5f0ff, #f5f0ff),
    linear-gradient(0deg, rgba(22, 27, 51, 0.08), rgba(22, 27, 51, 0.08)); */
  background: linear-gradient(0deg, rgba(22, 27, 51, 0.08) 0%, rgba(22, 27, 51, 0.08) 100%), #f5f0ff;
}

.tertiary:active {
  color: #7948e1;
  background: linear-gradient(0deg, rgba(22, 27, 51, 0.12) 0%, rgba(22, 27, 51, 0.12) 100%), #f5f0ff;
}

.tertiary.disabled {
  color: #9f72ff;
  background: #f5f0ff;
  opacity: 0.4;
}

.clean {
  display: inherit;
}

/* end variants */

/* Loading and disables */
.loading {
  cursor: not-allowed;
  position: relative;
}

.disabled {
  cursor: not-allowed;
}
.disabled svg {
  fill: rgba(22, 27, 51, 0.4);
}

/* end loading and variation */

/* Misc  */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loader:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-top-color: #3888e6;
  border-right-color: #0dd;
  border-bottom-color: #f90;
  animation: spinner 0.6s linear infinite;
}
