.mainTextPopup {
  text-align: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  font-weight: 800;
}

.imgPopup {
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 192px;
  height: 192px;
}

.minorTextPopup p b {
  color: var(--Accent-Violet-Default, #9f72ff);
}

@media (max-width: 800px) {
  .imgPopup {
    width: 120px;
    height: 120px;
  }
}

.popupButtons {
  display: flex;
  grid-column-gap: 4%;
  white-space: nowrap;
  justify-content: center;
  flex-direction: row;
}

.footerContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  padding: 0 30px;
}

.minorTextPopup {
  padding: 0 80px;
  text-align: center;
  display: block;
  margin: 13px auto 30px;
  color: var(--Text-Black-Primary, #161b33);
  white-space: break-spaces !important;
}

@media (max-width: 662px) {
  .footerContainer {
    flex-direction: column-reverse;
    padding: 0 0px;
    gap: 8px;
  }
}

@media (max-width: 481px) {
  .minorTextPopup {
    padding: 0 20px;
  }
}
