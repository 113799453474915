
.MenuButton {
  background: #fff;
  border: none;
  margin: 12px;
  font-size: 24px;
  padding: 8px;
  border-radius: 8px;
  color: hsla(230, 40%, 14%, 0.4);

  cursor: pointer;
  display: block;
}

.DropdownMenu {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 160px;
  z-index: 3; /* Ensure the menu appears above everything */
}

.MenuItem {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
}

.MenuItem:hover {
  background-color: #f1f1f1;
}

.MenuItem:first-child {
  color: #673ab7;
}

.MenuItem:last-child {
  color: #e91e63;
}
