.uploaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.SubHeader {
  display: block;
}

.uploader {
  display: flex;
  width: 80%;
  height: 520px;
  border-radius: 60px;
  border: 1px solid var(--Stroke-Light, rgba(22, 27, 51, 0.1));
  background: var(--Fill-White, #fff);
  text-align: center;
  align-items: center;
  align-self: center;
  padding: 24px;
  height: 380px;
  box-shadow: 0px 4px 40px 0px hsla(230, 32%, 25%, 0.08);
  max-width: 1156px;
}


.uploader_content {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  width: 100%;
  height: 100%;
  border-radius: 48px;
  border: 2px dashed var(--Stroke-Default, rgba(22, 27, 51, 0.2));
  outline: none;
}

.dragActive {
  background: rgba(0, 0, 0, 0.1);
}

.uploader_content_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}



.upload_button {
  display: flex;
  font-style: normal;
  line-height: normal;
  justify-content: center;
  align-items: center;
  gap: 8px;

  cursor: pointer;
  border: none;
  outline: none;
}

.upload_button svg {
  width: 32px;
  height: 32px;
}
button.upload_button:active,
button.upload_button:hover,
button.upload_button {
  text-decoration: none;
  color: var(--Text-White-Primary, #fff);
}

.divider-section{
  margin: 0px;
}

.uploader_title_desktop{
  display: block;
}

.uploader_title_mobile{
  display: none;
}

.upload_button_desktop{
  display: block;
}

.upload_button_mobile{
  display: none;
}

@media screen and (max-width: 650px) {
  .upload_button_desktop{
    display: none;
  }

  .upload_button_mobile{
    display: block;
  }

  .uploader_title_desktop{
    display: none;
  }
  
  .uploader_title_mobile{
    display: block;
  }

  .uploader_content{
    gap: 12px;
    border-radius: 20px;
  }

  .uploader{
    width: 91%;
    padding: 10px;
    border-radius: 20px;
    height: 138px;
  }

  .uploader_content_divider{
    display: none;
  }

  .SubHeader{
    display: none;
  }

  .upload_button {
    gap: 4px;

  }
  
  .upload_button svg {
    width: 24px;
    height: 24px;
  }

}
