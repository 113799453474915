.dialog {
  display: none !important;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 51;
}

@media (max-width: 800px) {
  .dialog{
    display: block !important;
  }
}





