.layout {
  height: 100vh;
  height: 100dvh;
}

.ContentWrapper {
  
  width: 100%;
  max-height: 100%;
  background: rgba(240, 240, 245, 1);
  overflow: hidden;
}

.NoHeader {
  height: 100vh;
  height: 100dvh;
}

@media (max-width: 481px) {
  .layout {
    overflow: hidden;
  }
}
