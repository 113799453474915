.Wrapper{
  overflow-x: hidden;
  
  width: 100%;
  max-height: 100%;
  background: #fff;
}

.ContentWrapper {
  width: 100%;
}
.NoHeader {
  height: 100vh;
  height: 100dvh;
}

.WithHeader {
  height: calc(100vh - 64px);
  height: calc(100dvh - 64px);
}
