.mainTextPopup {
    color: white;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 604px;
    text-align: center;
    height: 100%;
}

.starImage {
    /* margin-top: 74px; */
    width: 56px;
    height: 56px;
    padding: 12px;
    border-radius: 50%;
}

.starImage svg {
    width: 32px;
    height: 32px;
    fill: white;
}

.subscriptionType {
    margin-top: 4px;
    line-height: 21.82px;
    color: var(--Accent-Violet-Default, #9f72ff);

}

.minorTextPopup {
    margin-top: 12px;
    max-width: 444px;
    text-align: center;
    color: var(--Text-Black-Secondary)
}

.h2 {
    max-width: 360px;
    font-family: Nunito, sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: var(--Fill-Black);
    margin-top: 12px;
}

.featureBlocks {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.featureBlock {
    text-align: center;
    /* background: #F5F0FF; */
    border-radius: 12px;
    /* padding: 8px; */
    display: flex;
    gap: 2px;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    height: 68px;
    max-width: 120px;
}

.featureBlock svg {
    display: block;
    height: 20px;
    width: 20px;
}

.featureIcon {
    width: 36px;
    height: 36px;
    background: #F5F0FF;
    padding: 8px;
    border-radius: 8px;
}

.featureIcon svg {
    width: 20px;
    height: 20px;
    /* fill: white; */
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top_block{
    display: flex;
    align-items: center;
    height: 30%;
}

.bottom_block{
    display: flex;
    align-items: center;
    height: 70%;
    padding: 15px 0 0;
}

.feature_green svg {
    fill: #3FD898
}

.feature_star svg {
    fill: linear-gradient(109.4deg, #3A73E3 16.26%, #68A7F2 88.44%);
    fill: #69A8F2
}

.feature_purple svg {
    fill: linear-gradient(111.12deg, #854CFE 14.26%, #9F72FF 83.15%);
    fill: #9F72FF;
}

.feature_pink svg {
    fill: #ED79BF
}

.feature_green_header {
    background: linear-gradient(111.43deg, #08BE92 16.83%, #3ED797 85.22%);
}

.feature_star_header {
    background: linear-gradient(109.4deg, #3A73E3 16.26%, #68A7F2 88.44%);
}

.feature_purple_header {
    background: linear-gradient(111.12deg, #854CFE 14.26%, #9F72FF 83.15%);
}

.feature_pink_header {
    background: linear-gradient(109.04deg, #ED638C 13.68%, #ED78BE 86.67%);
}

.popupButtons {
    margin-top: 20px;
}

.feature_desktop {
    display: flex;
}

.feature_mobile {
    display: none;
}

.footerContainer {
    display: flex;
    gap: 16px;
}

.newProjectButton{
    width: auto;
}

.subscribeNowButton{
    width: auto;
}

/*  Mobile styles. Must be deleted in the future */
/* fit to editor mobile view */
@media (max-width: 690px) {
    .wrapper {
        width: 100%;
        height: 100%;
        /* justify-content: space-between; */
    }

    .mainTextPopup {
        justify-self: start;
    }

    .featureBlocks {
        /* flex-wrap: wrap; */
    }

    .featureBlock {
        /* white-space: nowrap; */
    }

    .feature_desktop {
        display: none;
    }

    .feature_mobile {
        display: flex;
    }
    .footerContainer {
        flex-direction: column-reverse;
    }
    .top_block{
        height: 25%;
    }
    
    .bottom_block{
        height: 75%;
        width: 90%;
        padding: 0;
    }
}

@media (max-height: 667px) {
    .body {
        transform: scale(0.9);
    }
    .top_block{
        height: 29%;
    }
    .bottom_block{
        height: 71%;
    }
}

@media (max-height: 568px) {
    .title {
        transform: scale(0.75);
    }
    .body {
        transform: scale(0.75);
    }
    .featureBlocks {
        width: 110%;
    }
}

@media (max-height: 512px) {
    .body {
        transform: scale(0.73);
    }
}
