.layout {
  overflow: hidden;
}

.layoutWithOutScroll{
  overflow: hidden;
}

.ContentWrapper {
  
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 100%;
  background: rgba(240, 240, 245, 1);
}

.NoHeader {
  height: 100vh;
  height: 100dvh;
}

.WithHeader {
  height: calc(100vh - 64px);
  height: calc(100dvh - 64px);
}
