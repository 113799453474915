.TabContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
}
.Tab {
  /* background-color: hsla(260, 100%, 97%, 1); */
  border-radius: 50px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid hsla(230, 40%, 14%, 0.1);
  outline: none;
}

.Tab:hover {
  border: 2px solid #8c56ff;
}

.active {
  background: var(--Gradient-Accent, linear-gradient(98deg, #8c56ff 22.71%, #c956ff 73.26%));
  border: 2px solid transparent;
}

.active:hover {
  border: 2px solid transparent;
}

.size-m {
  padding: 17px 24px 17px 24px;
  font-size: 16px;
  min-width: 210px;
}
.size-s {
  padding: 8px 16px 8px 16px;
  font-size: 12px;
  min-width: 148px;
}

.TabLabel {
  color: hsla(230, 40%, 14%, 0.4);
  font-weight: 700;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
  gap: 4px;
}

.TabLabel svg {
  margin-bottom: -5px;
}

.Tab.active > .TabLabel {
  /* color: hsla(259, 100%, 72%, 1); */
  color: hsla(0, 0%, 100%, 1);
}

.Tab.size-m > .TabLabel {
  font-size: 16px;
}

.Tab.size-s > .TabLabel {
  font-size: 12px;
}

.DiscountLabel {
  background: hsla(324, 76%, 70%, 1);
  padding: 8px 12px 8px 12px;
  color: #fff;
  margin-top: -12px;
  margin-bottom: -12px;
  margin-left: 6px;
  display: inline;
  border-radius: 50px;
  font-weight: 700;
  font-size: 12px;
}

@media (max-width: 720px) {
  .TabContainer {
    justify-content: start;
    overflow-x: scroll;
  }
}
