.ProfileBlock {
    margin-bottom: 20px;
}

.ProfileBlock input {
    width: 500px;
}


.ProviderContainer {
    padding: 12px;
    border: 1px solid var(--Stroke-Light);
    border-radius: 12px;
    display: flex;
    gap: 8px;
    width: 500px;
    align-items: center;
}

.ProviderDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.ProviderName {
    color: var(--Text-Black-Secondary);
}

.providerImage {
    width: 48px;
    height: 48px;
}

.providerImage svg {
    height: 100%;
    width: auto;
}

@media screen and (max-width: 800px) {
    .ProfileBlock input {
        width: 240px;
    }
    .ProviderContainer{
        width: 240px;
    }
}

@media (max-width: 481px) {
    .ProfileBlock input {
        width: 100%;
    }
    .ProviderContainer{
        width: 100%;
        min-width: 337px;
    }
  }