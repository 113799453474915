.ChangePassword {
    display: flex;
    flex-direction: column;
}

.ChangePassword input {
    width: 500px;
}

.ChangePassword .PasswordInput:not(:last-child) {
    margin-bottom: 16px;
}

.InputPensil {
    background-image: url('/public/pensil.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;
}

.SaveContainer {
    display: flex;
    width: 100%;
    justify-content: end;
}

.SaveButton {
    height: 56px;
    width: 193px;
}

.ToastBlock {
    padding: 12px 24px 12px 16px;
    border-radius: 8px;
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    max-width: 322px;
}

.SuccessBlock {
    color: rgba(14, 179, 110, 1);
    background-color: hsla(156, 100%, 95%, 1);
}

.ErrorBlock {
    color: #E055A9;
    background: #FDF2F9
}

.checkIcon svg {
    fill: rgba(14, 179, 110, 1);
    margin-top: 4px;
}

.SuccessText {
    font-weight: 700;
    font-size: 16px;
    text-decoration: underline;
    line-height: 21.82px;
}

@media screen and (max-width: 800px) {
    .ChangePassword input {
        width: 100%;
    }

    .SaveButton {
        width: 100%;
    }

    .ToastBlock {
        width: 100%;
        margin: 16px auto;
    }
}