.footerContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 0 50px;
}

.modal_content {
  position: relative;
  padding: 40px;
  border-radius: 60px;
  background-color: white;
  /*max-height: 486px;*/
  max-width: 686px;
  border: 1px solid rgba(22, 27, 51, 0.1);
}

.mainTextPopup {
  text-align: center;
  /* width: 400px; */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  font-weight: 800;
}

.imgPopup {
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 800px) {
  .imgPopup {
    width: 120px;
    height: 120px;
  }
}

.minorTextPopup {
  /*max-width: 450px;*/
  padding: 0 50px;
  text-align: center;
  display: block;
  margin: 13px auto 30px;
  color: var(--Text-Black-Primary, #161b33);
  white-space: break-spaces !important;
}

.buttonDiscount {
  background-color: var(--Accent-Pink-Default, #ed79bf);
  border-radius: 50px;
  padding: 2px 8px;
}

.buttonInput {
  display: inline-flex;
  gap: 8px;
}

.buttonText {
  align-self: center;
}

.popupButtons {
  display: flex;
  grid-column-gap: 4%;
  white-space: nowrap;
  justify-content: center;
  flex-direction: row;
}

.buttonLabel {
  display: flex;
  gap: 5px;
  align-items: center;
}

@media (max-width: 662px) {
  .popupButtons {
    flex-direction: column;
    gap: 8px;
  }
}

@media (max-width: 462px) {
  .footerContainer {
    padding: 0;
  }
}

.popoupCloseButton {
  position: absolute;
  cursor: pointer;
  top: 24px;
  right: 24px;
}

.creditsTextPopup {
  margin-top: -11px;
  margin-bottom: 10px;
  font-size: 16px;
  color: var(--Accent-Violet-Default, #9f72ff);
  font-weight: 700;
  text-align: center;
}

.creditsValue {
  font-size: 16px;
  color: var(--Accent-Violet-Default, #9f72ff);
  font-weight: 700;
}

.footerContainerRow {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  padding: 0 50px;
}

.minorTextPopupRemoveSubscription {
  /*max-width: 450px;*/
  padding: 0 50px;
  text-align: center;
  display: block;
  margin: 13px auto 30px;
  color: var(--Text-Black-Primary, #161b33);
  white-space: break-spaces !important;
}
@media (max-width: 481px) {
  .footerContainerRow {
    flex-direction: column;
    padding: 0 20px;
  }

  .minorTextPopupRemoveSubscription {
    padding: 0 20px;
  }
}


/*style for replace object popup*/
.uploaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 40px 40px;
  gap: 32px;
}

.SubHeader {
display: block;
}

.removeBackgroundToggle {
  margin-top: 16px;

}

.removeBackgroundIcon {
  fill: var(--Accent-Violet-Default);
  height: 18px;
  width: 18px;
  vertical-align: middle;
}

.toggleLabel {
  margin-right: 18px;
}

.toggle {
  margin-left: 8px;
}

.uploader {
  display: flex;
  height: 320px;
  width: 524px;
}

.uploader_content {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 2px dashed var(--Stroke-Default, rgba(22, 27, 51, 0.2));
  outline: none;

}

.dragActive {
  background: rgba(0, 0, 0, 0.1);
}

.uploader_content_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.upload_button {
  display: flex;
  font-style: normal;
  line-height: normal;
  justify-content: center;
  align-items: center;
  gap: 8px;

  cursor: pointer;
  border: none;
  outline: none;
}

.upload_button svg {
  width: 32px;
  height: 32px;
}
button.upload_button:active,
button.upload_button:hover,
button.upload_button {
  text-decoration: none;
  color: var(--Text-White-Primary, #fff);
}

.uploaderPopupTitle{
  text-align: left;
  width: 100%;
}

.lineUploaderInPopup {
  width: 44%;
  height: 0;
  border: 1px solid #c4c4c4;
  margin: 3px;
  margin-bottom: auto;
  margin-top: auto;
}

.uploader_content_divider{
  width: 100%;
}

.dividerSectionInPopup {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 300px;
  margin: 20px auto;
}

.selectButton{
  color: var(--Accent-Violet-Default, #9f72ff);
  border: 2px solid var(--Accent-Violet-Default, #9f72ff);
  fill: var(--Accent-Violet-Default, #9f72ff);
}

.selectButton:hover {
  /*opacity: 0.7;*/
  color: #744ad6;
  border: 2px solid #744ad6;
  fill: #744ad6;
}

/* .selectButton:active {
  color: var(--Accent-Violet-Default, #9f72ff);
  border: 2px solid var(--Accent-Violet-Default, #9f72ff);
  fill: var(--Accent-Violet-Default, #9f72ff);

  background: rgba(159, 114, 255, 0.12);
}

.selectButton.disabled {
  opacity: 0.4;
  color: var(--Accent-Violet-Default, #9f72ff);
  border: 2px solid var(--Accent-Violet-Default, #9f72ff);
  fill: var(--Accent-Violet-Default, #9f72ff);
} */

.dialog {
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 102;
}

.inner_wrapper {
  border-radius: 60px;
  background: white;
  background-size: 100% 100%;
  /*max-height: 486px;*/
  max-width: 684px;
}

.inner_wrapper_wide {
  min-width: 300px;
  /*min-height: 646px;*/
}

.top_dock {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin: 40px 40px 0 40px;
}

.cursor_pointer {
  cursor: pointer;
}


.close {
  position: absolute;
  top:0;
  right: 0;
}

.container {
  padding: 0 40px 40px 40px;
}

.body {
  display: block;
}

.SubHeaderMobile{
  display: none;
  font-style: normal;
  line-height: normal;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  outline: none;
}

.SubHeaderMobile svg {
  fill: rgba(159, 114, 255, 1);
  width: 32px;
  height: 32px;
}

/*  Mobile styles. Must be deleted in the future */
/* fit to editor mobile view */
@media (max-width: 690px) {
  .uploader{
    width: 100%;
    height: 100%;
  }
  
  .uploaderContainer{
    height: 100%;
    justify-content: flex-start;
  }

  .inner_wrapper {
    margin: 0;
    height: 100%;
    width: 100%;
    border-radius: 0;
    background: white;
    background-size: cover, 100% 100%;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    padding-bottom: 73px;
  }

  .mpcard_pro {
    margin-top: 53px;
  }

  .top_dock {
    margin: 20px 20px 0 20px
  }

  .container {
    height: 100%;
    padding: 0;
  }

  .body {
    height: 100%;
  }

  h1 {
    font-size: 22px;
  }
}

@media screen and (max-width: 450px) {
  .removeBackgroundToggle {
      margin-bottom: 26px;
  }

  .SubHeader {
    display: none;
  }

  .dividerSectionInPopup{
    display: none;
  }

  .selectButton{
    display: none;
  }

  .SubHeaderMobile{
    display: flex;
  }
}




