.ProjectList {
  display: grid;
  grid-template-columns: repeat(4, 22.75%);
  gap: 24px 3%;
  flex-wrap: wrap;
}

@media (max-width: 965px) {
  
  .ProjectList {
    grid-template-columns: repeat(3, 31.33%);
    gap: 16px 3%;
  }
}

@media (max-width: 700px) {
  
  .ProjectList {
    grid-template-columns: repeat(2, 48.5%);
    gap: 16px 3%;
  }
}

