
.link{
  height: 100%;
  display: block;
}

.Card {

  border: 1px solid hsla(230, 40%, 14%, 0.1);
  border-radius: 20px;
  width: 100%;
  height: 100%;

  position: relative;
  overflow: hidden;
}

.CardImage {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CardImage img {
  width: 100%;
  height: auto;
  object-fit: contain;
  aspect-ratio: 3 / 4;
}

.CardOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 8px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.0); /* Transparent by default */
  transition: background-color 0.3s ease; /* Smooth transition of background on hover */
}

.Card:hover .CardOverlay {
  background-color: #00000020;
}


.MenuButton {
  background: #fff;
  border: none;
  margin: 5px;
  font-size: 16px;
  outline: none;
  padding: 8px;
  border-radius: 8px;
  color: hsla(230, 40%, 14%, 0.4);

  cursor: pointer;
  display: block;
}

.MenuButtonActive {
  color: hsla(259, 100%, 72%, 1);
}

.DropdownMenu {
  position: absolute;
  top: 72px;
  right: 12px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 3; /* Ensure the menu appears above everything */
  outline: none;

  max-width: 186px;
  width: 163px;
  min-width: 155px;
}

.MenuItem {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 700;
  color: hsla(230, 40%, 14%, 1);
  font-size: 12px;
  gap: 8px;
  padding: 12px 10px;
}

.MenuItem:hover {
  background-color: hsla(263, 100%, 98%, 1);
  border-radius: 8px;
}

.colorDuplicate {
  fill: hsla(259, 100%, 72%, 1);
}

.colorDelete {
  fill: #e055a9;
}

@media (max-width: 1000px) {
  .DropdownMenu{
    width: auto;
  }
}

@media (max-width: 650px) {
  .MenuButton {
    margin: 2px;
  }
  .DropdownMenu {
    width: 155px;
    right: 10px;
  }
  .MenuItem {
    font-size: 11px;
    gap: 5px;
  }
}

