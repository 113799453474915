.upload {
    display: flex;
    /* height: 400px; */
    padding: 56px 48px;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    border: 1px solid var(--Stroke-Light, rgba(22, 27, 51, 0.1));
    background: var(--Fill-White, #fff);
    align-self: center;
    margin: auto;
}

.content {
    display: flex;
    width: 352px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.forgotPasswordTitle {
    display: flex;
    align-items: center;
}

.backButton {
    width: 34px;
    height: 72px;
    cursor: pointer;
}

.other {
    display: flex;
    align-items: center;
    gap: 8px;
}

.input {
    display: flex;
    padding: 17px 24px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 50px;
    border: 1px solid var(--Stroke-Default, rgba(22, 27, 51, 0.2));
    backdrop-filter: blur(15px);
}

.input::placeholder {
    color: var(--Text-Black-Tertiary, rgba(22, 27, 51, 0.4));
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}
.inputLabel {
    display: block;
    padding-left: 24px;
    font-weight: bold;
    margin-bottom: 2px;
}

.labelError {
    color: var(--Accent-Pink-Dark, #e055a9) !important;
}

.inputDescription {
    display: block;
    width: 100%;
    text-align: center;
    color: var(--Text-Black-Tertiary, rgba(22, 27, 51, 0.4));
}

.requiredMark {
    color: var(--Accent-Violet-Default);
}

.inputError {
    border: 1px solid var(--Accent-Pink-Dark, #e055a9);
    background-image: url('/public/error_sign.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;
    padding-right: 25px;
}

.errorText {
    color: var(--Accent-Pink-Dark, #e055a9);
    font-size: 12px;
    text-align: left;
    display: block;
    width: 100%;
    padding-left: 24px;
}

.logoutButton {
    color: var(--Accent-Pink-Dark, #e055a9);
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.logoutButton:hover {
    background-color: #d32f2f;
}

.creditInfoButtons {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
}

.simpleLink {
    display: flex;
    height: 56px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 50px;
    background: var(--Fill-Button, #f5f0ff);
}

.mainLink {
    display: flex;
    height: 56px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 50px;
    background: var(--Gradient-Accent, linear-gradient(98deg, #8c56ff 22.71%, #c956ff 73.26%));
}

.presentBlock {
    padding: 16px 32px 16px 32px;
    background-color: #F5F0FF;
    border-radius: 50px;

    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.presentImage {
    height: 40px;
    width: 40px;
}

.thanksModal {
    gap: 0px !important;
}

.forgotPassword {
    margin-top: -20px;
    text-decoration-line: underline;
}

.content span a {
    text-decoration-line: underline;
}

.policyAndTermsContainer{
    max-width: 309px;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 450px) {
    .upload {
        padding: 32px 16px;
        border-radius: 0px;
        border: none;
        height: 100%;
        width: 100%;
    }

    .content {
        width: 100%;
    }

    .presentBlock {
        width: 100%;
        justify-content: center;
    }

    .textInPresentBlock {
        min-width: 215px;
        width: 62%;
    }

    .thanksModal {
        margin-top: -100px;
    }
}
