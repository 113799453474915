.big_buttom_element{
  display: flex;
  flex-direction: column;
  border: 2px solid rgba(22, 27, 51, 0.1);
  width: 100%;
  height: auto;
  padding: 16px;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 12px;
}

.big_buttom_main_text{
  color: rgba(22, 27, 51, 1);
  font-family: Nunito;
  font-size: 22px;
  font-weight: 700;
  text-align: left;
}

.big_buttom_minor_text{
  font-family: PT Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: rgba(22, 27, 51, 0.6);
  margin-bottom: 16px;
}

.big_buttom_mp_line{
  display: flex;
  gap: 4px;
}

.big_buttom_mp_element{
  height: 40px;
  width: 40px;
}