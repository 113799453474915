.logoList{
    display: inline-flex;
    gap: 20px;
}

.betaVersion{
    border: 1px solid #161b33;
    border-radius: 100px;
    opacity: .4;
    width: 125px;
    text-align: center;
    height: 35px;
    margin: auto;
    font-size: 16px;
    line-height: 160%;
}