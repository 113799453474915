.Loading_outer {
    background: var(--Fill-Background, #F0F0F5);
    position: fixed;
    top: 64px;
    width: 100%;
    height: calc(100% - 64px);
    z-index: 103;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Loading_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 448px;
    height: 328px;

    border-radius: 60px;
    border: 1px solid var(--Stroke-Light, rgba(22, 27, 51, 0.10));
    background: var(--Fill-White, #FFF);
}

.Loading_text {
    color: var(--Text-Black-Primary, #161B33);
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.Loading_loader {
    animation: spin 2s linear infinite;
}