.polotno-side-panel {
  padding: 0 !important;
  margin: 0 !important;
}


.fullHW {
  width: 100%;
  height: calc(100vh - 64px) !important;
  height: calc(100dvh - 64px) !important;
}
.polotno-side-tabs-container {
  padding: 24px !important;
}

.polotno-side-panel-tab {
  display: flex;
  width: 64px !important;
  padding: 6px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto !important;
  gap: 4px;
  color: var(--Text-Black-Tertiary, rgba(22, 27, 51, 0.4));
  text-align: center;
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; 
  fill: var(--Fill-Black);
  fill-opacity: 0.4;
  background: transparent;
  white-space: break-spaces !important;
}

.polotno-side-panel-tab.active {
  color: var(--Text-Black-Primary, #161b33);
  fill: var(--Accent-Violet-Default);
  fill-opacity: 1;
  background-color: transparent !important;
}

.polotno-side-panel-tab:hover {
  color: var(--Text-Black-Primary, #161b33);
  fill: #9f72ff;
  fill-opacity: 1;
  background-color: transparent !important;
}

.tab-icon {
  width: 32px;
  height: 32px;

}

.polotno-side-panel-tab:hover {
  background-color: transparent !important;
}

.polotno-side-panel-tab.active:hover {
  background-color: transparent !important;
}

.polotno-panel-container {
  width: 372px !important;
}

.overlayModal{
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
}

.tool-panel {
  display: flex;
  width: 372px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: var(--Fill-White, #fff);
  height: 100%;

  margin-left: -10px;
  margin-right: -8px;
}

.tool-panel_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.tool-panel_content_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  overflow-y: auto;
}

.tool-panel_content_wrapper:focus{
  outline: none;
  outline-offset: 0px;
}

.tool-panel_content_text {
  display: flex;
  padding: 24px 38px 20px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.tool-panel_content_suggested {
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--Fill-Button, #f5f0ff);

  margin: 0px 12px;
}

.tool-panel_content_suggested h3 {
  text-align: left;
}

.tool-panel_content_category_warning {
  display: flex;
  padding: 20px 12px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  background: var(--Fill-White);
  border-top: 1px solid rgba(22, 27, 51, 0.1);
  box-shadow: 0px -4px 8px 0px rgba(2, 41, 88, 0.08);
}

.tool-panel_content_category_warning_text {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;

  padding: 0 0 0 12px;
}

.tool-panel_content_category_suggested_templates {
  display: flex;
  width: 306px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}

.tool-panel_content_category_wrapper {
  display: flex;
  width: 302px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 16px;
}

.menuTemplates {
  display: inline-flex;
  width: 100%;
}

.info_wrapper {
  width: 97%;
  gap: 13px;
  margin-left: 10px;
  padding: 0px 0px 0px 13px;
}

.info_elements {
  width: 45% !important;
  position: relative !important;
}

.info_img {
  width: 100% !important;
  height: 195px !important;
  z-index: 0;
}

.premium_icon {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 100;
}

.delete_info {
  width: 100% !important;
  height: 195px !important;
  display: flex;
}

.tool-panel_about_background{
  padding: 0 12px 0px;
}

.info_text {
  padding: 0px 24px 18px;
  font-family:
    PT Sans,
    serif;
  font-size: 16px;
  color: rgba(22, 27, 51, 0.6);
  font-weight: 400;
}

.panel_content_template_delete {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.infogaphicsTemplate {
  display: inline-block;
  fill: var(--Text-Black-Tertiary);
}

.info_elements.active:after {
  content: '';
  background: rgba(159, 114, 255, 0.2);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  border-radius: 8px;
}

.tool-panel_content_template_image_block{
  position: relative;
  background-color: transparent;
  width: 94px;
  height: 94px;
}

.tool-panel_content_template_image_block.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(159, 114, 255, 0.2);
  border-radius: 8px;
  border: 2px solid var(--Stroke-Default);
  border-color: var(--Accent-Violet-Dark,#9F72FF) !important;
}

.aiCardSelected{
  position: relative;
  background-color: transparent;
}

.aiCardSelected.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(159, 114, 255, 0.2);
  border-radius: 8px;
  border: 3px solid var(--Stroke-Default);
  border-color: var(--Accent-Violet-Dark,#9F72FF) !important;
}

.dwlButton {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 8px;
}

button.btn-dwl-mobile {
  border-radius: 50%;
  padding: 0 12px;
  margin-right: 12px;
  width: 40px;
  min-width: 40px;
}

.go3960841851 > div:nth-child(1) > p:nth-child(3) {
  /*Hack to hide "photos by Unsplash" notice in english*/
  display: none;
}

.go3960841851{
  padding: 0px !important;
}

.tab-divider {
  display: none;
}

.tab-wrapper {
  position: relative;
}

.tab-wrapper.divider::after {
  content: '';
  border-bottom: 1px solid rgba(22, 27, 51, 0.2);
  width: 100%;
  margin: 6px 0;
  padding-top: 10px;
  display: block;
  cursor: default;
}

.editor-hidden-desktop {
  display: none;
}

.editor-hidden-mobile {
  display: block;
}

.position-warning-button {
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.position-warning-button.disable{
  opacity: 40%;
  pointer-events: none;
}

.tool-panel_header_line{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 18px;
  align-items: center;
}

.tool-panel_update_content_suggested_button{
  cursor: pointer;
  display: flex;
}

.tool-panel_content_category_tooltip{
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tool-panel_content_category_tooltip .tool-panel_content_category_tooltip_text {
  visibility: hidden;
  width: 316px;
  background-color: rgba(121, 72, 225, 1);
  color: rgba(255, 255, 255, 1);
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -158px;
  opacity: 0;
  transition: opacity 0.3s;

  font-family: PT Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.tool-panel_content_category_tooltip .tool-panel_content_category_tooltip_text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(121, 72, 225, 1) transparent transparent transparent; /* Make the arrow */
}

.tool-panel_content_category_tooltip:hover .tool-panel_content_category_tooltip_text,
.tool-panel_content_category_tooltip:focus .tool-panel_content_category_tooltip_text {
  visibility: visible;
  opacity: 1;
}

.tool-panel_update_content_suggested_button.disable{
  opacity: 40%;
  pointer-events: none;
}

.tool-panel_content_category_warning_label{
  display: flex;
  gap: 4px;
}

.warning-button-label{
  display: flex;
  gap: 4px;
  justify-content: center;
}

.warning-button-label::before {
  content: "";
  transform: skew(-20deg) translateX(-100%);
  position: absolute;
  top: -500%;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.3), hsla(0, 0%, 100%, 0));
}

.animate_generate_button .warning-button-label::before {
 animation: shine 0.75s both;
}

@keyframes shine {
  to {
    transform: translateX(100%);
  }
}

.tool-panel_content_templates{
  display: flex;
  flex-direction: column;
  gap:8px;
}

.tool-panel_header_and_star{
  position: relative;
  display: flex;
}

.infographics_text{
  padding: 24px 0px 4px 24px;
}

.content_default_wrapper{
  gap: 0px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.content_info_wrapper{
  gap: 0px;
}

.return_object_button{
  border: 2px solid rgba(159, 114, 255, 1);
  color: rgb(159, 114, 255);
}

.return_object_button:hover {
  border: 2px solid rgba(159, 114, 255, 1);
  color: rgb(159, 114, 255);
}

.return_object_button:active {
  border: 2px solid rgba(159, 114, 255, 1);
  color: rgb(159, 114, 255);
}

.return_object_button.disabled {
  border: 2px solid rgba(159, 114, 255, 1);
  color: rgb(159, 114, 255);
}

.warning-button-label-price{
  display: flex;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  height: 24px;
  width: 43px;
  padding: 6px 8px 4px 8px;
  gap: 2px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  margin-top: -2px;
}

.content_custom_background_wrapper{
  height: 100%;
}

.sizeCard_text{
  padding: 24px 0px 4px;
  justify-content: normal;
  gap: 4px;
}

.sizeCard_minor_text {
  padding: 4px 0px 20px;
  font-family:
    PT Sans,
    serif;
  font-size: 16px;
  color: rgba(22, 27, 51, 0.6);
  font-weight: 400;
}

.content_sizeCard_wrapper{
  gap: 0px;
  padding: 0px 24px 20px;
}

.big_button_selection.active {
  border: 2px solid rgba(159, 114, 255, 1);
}

.backButton {
  display: none;
  align-items: center;
  gap: 10px;
  height: 64px;
  cursor: pointer;
}

.backButton svg {
  fill: hsla(259, 72%, 58%, 1);
  height: 32px;
  width: 32px;
}

.backButtonInAddImage {
  display: none;
  align-items: center;
  gap: 10px;
  height: 64px;
  cursor: pointer;
}

.backButtonInAddImage svg {
  fill: hsla(259, 72%, 58%, 1);
  height: 32px;
  width: 32px;
}

.infographics_title{
  display: flex;
  align-self: stretch;
}

.createBackgroundDesktop{
  display: block;
}

.createBackgroundMobile {
  background: #F5F0FF;
  display: none;
  align-items: center;
  gap: 10px;
  height: 64px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  justify-content: center;
}

.createBackgroundMobile svg {
  fill: #9F72FF;
  height: 24px;
  width: 24px;
}

/*  hides + "add empty page"*/
.bp5-navbar.polotno-pages-timeline .bp5-button.bp5-minimal {
  display: none !important;
}

.dividerDesktop{
  display: block;
}

.sectionTabMobile{
  display: none;
}

.polotno-side-panel-tab svg {
  width: 32px;
  height: 32px;
}


@media (max-width: 800px) {
  .dividerDesktop
  {
    display: none;
  }
  .sectionTabMobile{
    display: block;
  }

  .createBackgroundDesktop{
    display: none;
  }
  .createBackgroundMobile{
    display: flex;
    position: absolute;
    right: 16px;
    top: 38px;
  }
  .go3960841851.bp5-navbar.polotno-panel-container {
    height: calc(100vh - 156px) !important;
    height: calc(100dvh - 156px) !important;
  }
  .tool-panel_content_category_suggested_templates {
    padding: 0 18px;
  }
  .tool-panel_content_templates {
    margin: 0 16px;
  }
  .tool-panel_content_suggested {
    margin: 0;
    border-radius: 0px;
  }
  .content_AI_wrapper {
    padding: 20px 0 0 0;
    margin: 0 -10px 0 -10px;
    width: calc(100% + 20px);
  }
  .tool-panel_content_text {
    display: block;
    padding: 32px 0px 20px 0px;
    text-align: center;
  }
  .info_text {
    text-align: center;
    width: 100%;
  }

  .backButtonInAddImage{
    display: block;
    position: absolute;

    padding: 0px 0px 8px 16px;
    left: 10px;
    top: 35px;
  }
  .backButton {
    display: block;
    position: absolute;

    padding: 0px 0px 8px 16px;
  }
  .fullHW {
    width: 100%;
    height: calc(100vh - 64px);
    height: calc(100dvh - 64px);
  }
  .sidePanelWrap{
    height: 92px !important;
    position: static;
    z-index: 102;
  }

  .polotno-side-panel-tab div:nth-child(2){
    padding-top: 3px !important;
  }

  .tool-panel{
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .tool-panel_content_category_warning{
    margin-left: -10px;
    margin-right: -10px;
  }
  .tool-panel_content_category_warning_text {
    width: 100%;
    justify-content: flex-start;
  }
  .position-warning-button {
    max-width: 400px;
  }
  .editor-hidden-desktop {
    display: block;
  }
  .editor-hidden-mobile {
    display: none;
  }
  .tab-divider {
    border-right: 1px solid #161b3333;
    border-bottom: none;
    height: 70px;
    display: block;
    position: absolute;
    left: 90px;
  }
  .tab-wrapper.divider::after {
    display: none;
  }

  .polotno-side-tabs-inner {
    gap: 16px;
    align-items: baseline;
    justify-content: center;
  }
  .polotno-side-tabs-container {
    padding: 4px 12px !important;
  }
  .polotno-side-panel {
    margin-bottom: 64px !important;
  }

  /*  divider in panel */
  .bp5-divider {
    /* ⚠️ don't use display:none here due we need a space that devider takes */
    border: none !important;
    border-color: transparent !important;
    margin: 0px 0px !important;
  }

  /* content of panel */
  .polotno-panel-container {
    min-width: 370px;
    width: 100% !important;
    border-radius: 24px 24px 0 0;
    bottom: 92px !important;
    box-shadow: none !important;
    padding: 10px 10px 0 10px;
  }
  .go1097019230 {
    background: transparent !important;
    bottom: 130px !important;
    display: none !important;
  }
  .tool-panel {
    width: 100%;
  }
  .tool-panel_content_category_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .tool-panel_content_category_wrapper.mobile-flex-start {
    justify-content: flex-start;
  }
  .tool-panel_content_wrapper {
    align-items: center;
  }

  .info_elements {
    width: 114px !important;
    height: 152px !important;
    max-width: 145px;
  }
  .delete_info,
  .info_img {
    width: 114px !important;
    height: 152px !important;
  }

  .info_wrapper {
    width: 97%;
    gap: 8px;
    margin-left: 0px;

    padding: 0;
  }

  /* AI bg */
  .tool-panel_content_category_suggested_templates {
    width: 100%;
    gap: 4px;
    /* allow scroll */
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }

  .tool-panel_content_suggested h3 {
    text-align: center;
  }

  .tool-panel_content_category h3 {
    text-align: left;
  }

  .go3144864827.bp5-navbar-group {
    flex-grow: 1;
  }

  .tool-panel_content_category_tooltip .tool-panel_content_category_tooltip_text{
    margin-left: -303px;
  }

  .tool-panel_content_category_tooltip .tool-panel_content_category_tooltip_text::after {
    left: 96%;
  }
}

/* hide Polorno's Sidebar collapse button */
.go3136444743 {
  display: none !important;
}

.buttonTextReplaceObject{
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
