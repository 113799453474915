.dialog {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 103;
}

.inner_wrapper {
  border-radius: 60px;
  background: url('paywall_background_desktop.svg'), linear-gradient(98.35deg, #8C56FF 22.71%, #C956FF 73.26%);
  background-size: 106% 100%;
  /*max-height: 486px;*/
  max-width: 684px;
}

.inner_wrapper_wide {
  min-width: 300px;
  /* min-height: 646px; */
  height: 672px;
}

.top_dock {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  /* margin: 40px 40px 0 40px; */
}

.cursor_pointer {
  cursor: pointer;
}


.close {
  position: absolute;
  top: 40px;
  right: 40px;
}

.container {
  /* padding: 0 40px 40px 40px; */
  height: 100%;
}

.body {
  display: block;
  height: 100%;
}

/*  Mobile styles. Must be deleted in the future */
/* fit to editor mobile view */
@media (max-width: 690px) {
  .inner_wrapper {
    margin: 0;
    height: 100%;
    width: 100%;
    border-radius: 0;
    background: url('paywall_mobile_background.svg'), linear-gradient(98.35deg, #8C56FF 22.71%, #C956FF 73.26%);
    background-size: cover, 100% 100%;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    /* padding-bottom: 73px; */
  }

  /* .top_dock {
    top: 40px;
    right: 40px;
  } */

  .container {
    height: 100%;
    /* padding: 0 40px; */
  }

  .body {
    height: 100%;
  }

  h1 {
    font-size: 22px;
  }
}
