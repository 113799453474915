.footerContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 0 50px;
}

.modal_content {
  position: relative;
  padding: 40px;
  border-radius: 60px;
  background-color: white;
  /*max-height: 486px;*/
  max-width: 686px;
  border: 1px solid rgba(22, 27, 51, 0.1);
}

.mainTextPopup {
  text-align: center;
  /* width: 400px; */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  font-weight: 800;
}

.blackFridayTitle {
  padding: 0 50px;
}

.blackFridayTitle b {
  background: linear-gradient(90deg, #8C57FF 0%, #C957FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.firstMinorText {
  max-width: 520px;
  color: var(--Text-Black-Secondary, rgba(22, 27, 51, 0.6)) !important;
}

.minorText18size {
  font-size: 18px;
}

.firstMinorTextMargin{
  margin-bottom: 0px;
}

.firstMinorText b {
  background: radial-gradient(49.86% 49.86% at 49.86% 92.68%, #812FFB 0%, #6C00E7 75.47%, #550AC0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.secondMinorText b {
  background: radial-gradient(49.86% 49.86% at 49.86% 92.68%, #812FFB 0%, #6C00E7 75.47%, #550AC0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.imgPopup {
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.imgPopupBlackFriday{
  margin-top: -40px;
  height: 290px;
}

.blackFridayButton{
  width: 75%;
  font-weight: 800;
}

@media (max-width: 800px) {
  .imgPopup {
    width: 120px;
    height: 120px;
  }
  .imgPopupBlackFriday{
    width: auto;
    height: 180px;
  }
  .blackFridayTitle{
    padding: 0;
    font-size: 20px;
    line-height: 27.28px;
  }
  .blackFridayButton{
    width: 100%;
  }
}

.minorTextPopup {
  /*max-width: 450px;*/
  padding: 0 50px;
  text-align: center;
  display: block;
  margin: 13px auto 30px;
  color: var(--Text-Black-Primary, #161b33);
  white-space: break-spaces !important;
}

.minorTextPopup p b {
  color: #9f72ff;
}

.buttonDiscount {
  background-color: var(--Accent-Pink-Default, #ed79bf);
  border-radius: 50px;
  padding: 2px 8px;
}

.buttonInput {
  display: inline-flex;
  gap: 8px;
}

.buttonText {
  align-self: center;
}

.popupButtons {
  display: flex;
  grid-column-gap: 4%;
  white-space: nowrap;
  justify-content: center;
  flex-direction: row;
}

.buttonLabel {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 662px) {
  .popupButtons {
    flex-direction: column;
    gap: 8px;
  }
  .popupButtonsBlackFridayTitle {
    flex-direction: row;
  }
  .minorTextPopup {
    padding: 0;
  }
}

@media (max-width: 462px) {
  .footerContainer {
    padding: 0;
  }

  .bodyBlackFriday{
    margin-left: -10px;
    margin-right: -10px;
  }
}

.popoupCloseButton {
  position: absolute;
  cursor: pointer;
  top: 24px;
  right: 24px;
}

.creditsTextPopup {
  margin-top: -11px;
  margin-bottom: 10px;
  font-size: 16px;
  color: var(--Accent-Violet-Default, #9f72ff);
  font-weight: 700;
  text-align: center;
}

.creditsValue {
  font-size: 16px;
  color: var(--Accent-Violet-Default, #9f72ff);
  font-weight: 700;
}

.footerContainerRow {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  padding: 0 50px;
}

.minorTextPopupRemoveSubscription {
  /*max-width: 450px;*/
  padding: 0 50px;
  text-align: center;
  display: block;
  margin: 13px auto 30px;
  color: var(--Text-Black-Primary, #161b33);
  white-space: break-spaces !important;
}
@media (max-width: 481px) {
  .footerContainerRow {
    flex-direction: column;
    padding: 0 20px;
  }

  .minorTextPopupRemoveSubscription {
    padding: 0 20px;
  }
}
