.CardPlan {
  border-radius: 40px;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 320px;
  min-width: 320px;
  background: hsla(0, 0%, 100%, 1);
  border: 2px solid hsla(230, 40%, 14%, 0.1);
}

.highlighted {
  border: 2px solid hsla(259, 100%, 67%, 1);
}

.clientTypeTip {
  margin: 0 auto;
  color: var(--Accent-Violet, #9f72ff);
  padding: 12px 20px;
  background: var(--Accent-Violet-Light, #F5F0FF);
  border-radius: 8px;
  text-align: center;
  width: 234px;
}

.Header {
  color: hsla(259, 100%, 72%, 1);
  font-weight: 700;
  font-size: 22px;
  line-height: 30.1px;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 8px;
}

.SubHeader {
  padding: 4px 12px;
  margin-top: -4px;
  margin-bottom: -4px;
  margin-left: 4px;
  /* margin-top: -8px; */
  border-radius: 50px;
  font-weight: 700;
  font-size: 12px;
  color: #fff;
  background: linear-gradient(111.12deg, #854cfe 14.26%, #9f72ff 83.15%);
}

.Price {
  text-align: center;
  font-weight: 800;
  color: hsla(230, 40%, 14%, 1);
  font-size: 32px;
  line-height: 36px;
}
.PricePostfix {
  color: hsla(230, 40%, 14%, 0.6);
  font-size: 16px;
  font-weight: 700;
}

.PriceDescription {
  color: hsla(230, 40%, 14%, 0.6);
  font-size: 16px;
  font-weight: 700px;
  text-align: center;
}

.Divider {
  height: 1px;
  display: block;
}

.Divider::after {
  content: ' ';
  width: 100%;
  display: block;
  border-bottom: 1px solid hsla(230, 40%, 14%, 0.1);
}

/* ul.FeaturesList {
} */

.FeaturesList {
  margin-top: -4px;
  font-weight: 400;
  font-family: PT Sans;
  font-size: 16px;
  color: hsla(230, 40%, 14%, 0.6);
}

.FeatureIcon svg {
  display: inline;
  width: 20px;
  height: 20px;
}
.Feature {
  display: flex;
  gap: 8px;
  /* align-items: center; */
}

.buttonWithTooltip {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  overflow: visible;
}

@media (max-width: 481px) {
  ul.FeaturesList {
    margin-top: -14px;
  }
  .CardPlan {
    max-width: 362px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltip_text {
  visibility: hidden;
  width: 316px;
  background-color: rgba(121, 72, 225, 1);
  color: rgba(255, 255, 255, 1);
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -158px;
  opacity: 0;
  transition: opacity 0.3s;

  font-family: PT Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.tooltip .tooltip_text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(121, 72, 225, 1) transparent transparent transparent; /* Make the arrow */
}

.tooltip:hover .tooltip_text,
.tooltip:focus .tooltip_text {
  visibility: visible;
  opacity: 1;
}

.tooltipFallback {
  font-size: 12px;
  text-align: center;
  margin-top: 12px;
  color: var(--Text-Black-Tertiary, rgba(22, 27, 51, 0.4));
}

.DiscountBlock {
  display: flex;
  flex-direction: column;
}

.DiscountBlockPrice {
  font-weight: 400;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.strike {
  text-decoration: line-through;
  
}

.DiscountPercentBubble {
  background-color: hsla(324, 76%, 70%, 1);
  padding: 12px 6px;
  min-width: 76px;
  font-size: 12px;
  border-radius: 50px;
  color: hsla(0, 0%, 100%, 1);
}

.DiscountPricePostfix {
  font-weight: 700;
  font-size: 16px;
  color: hsla(324, 76%, 70%, 1);
}

.DiscountFinalPrice {

  color: hsla(324, 76%, 70%, 1);
  font-weight: 800;
  font-size: 32px;
  line-height: 36px;
  margin-top: 8px;
}
